const regex = {
  password:
    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&+~^|{}:;<>])[A-Za-z\d@$!%#*?&+~^|{}:;<>]{8,30}$/,
  zip_code: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  email_admins:
    /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])(,)?)+$/i,
  email:
    /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])(,)?)+$/i,
};
export default regex;
