const validation = {
  email: {
    required: "Email is required",
    existsEmail: "A user is already registered with this e-mail address",
    invalid: "Please provide a valid email address",
  },
  password: {
    required: "Password is required",
    min: "Password must be at least 8 characters",
    invalidPwdRegex: "Please provide a valid password",
  },
  currentPassword: {
    required: "*Current password is required",
  },
  newPassword: {
    required: "*New password is required",
    min: "*New password must be at least 8 characters",
    invalidPwdRegex: "*Please provide a valid password",
  },
  confirmPassword: {
    required: "*Confirm password is required",
    matched: "*Passwords are not matching",
  },
  firstName: {
    required: "First name is required",
  },
  lastName: {
    required: "Last name is required",
  },
  address: {
    required: "Address is required",
  },
  city: {
    required: "City is required",
  },
  zipCode: {
    required: "Zip code is required",
  },
  state: {
    required: "State is required",
  },
};

export default validation;
