import { clsx } from "clsx";

export type PasswordRequirementsProps = {
  readonly className?: string;
};

export function PasswordRequirements({
  className: classNameProp,
}: PasswordRequirementsProps) {
  const className = clsx("rounded-xl border px-8 py-9", classNameProp);

  return (
    <aside className={className}>
      <h1 className="font-semibold text-lg">Password Requirements</h1>
      <p>
        Your password must be classified as at least Strong. A good password
        consists of:
      </p>
      <ul className="list-inside list-disc">
        <li>
          <span>8 or more characters</span>
        </li>
        <li>
          <span>Mixture of letters and numbers</span>
        </li>
        <li>
          <span>Mixture of upper and lowercase</span>
        </li>
        <li>
          <span>Special characters</span>
        </li>
        <li>
          <span>Non-dictionary words</span>
        </li>
      </ul>
    </aside>
  );
}
