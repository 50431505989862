import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, ButtonProps } from "antd";
import React from "react";

type AppButtonProps = ButtonProps;

const ButtonStyles = styled(Button)``;

const AppButton: React.FC<AppButtonProps> = ({
  children,
  className,
  ...rest
}) => (
  <ButtonStyles
    {...rest}
    className={`${className}
      bg-snap-blue border-solid border-snap-blue
       w-full font-bold font-15 text-white`}
    css={css`
      border-width: 2px;
      border-radius: 50px;
      font-size: 15px;
      height: 52px;
      @media (max-width: 550px) {
        font-size: 13px;
        height: 45px;
      }
    `}
  >
    {children}
  </ButtonStyles>
);

export default AppButton;
