import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "@reach/router";
import { Divider, message } from "antd";
import { navigate } from "gatsby";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { object, ref, string } from "yup";

import { useAddAlert } from "../alert";
import { analyticsEnqueueEvent } from "../analytics";
import PadlockIcon from "../assets/images/Padlock.png";
import { LayoutDashboard } from "../components";
import AppButton from "../components/BaseComponents/Button";
import ErrorMessage from "../components/BaseComponents/ErrorMessage";
import AppInput from "../components/BaseComponents/Input";
import { sessionSignIn, sessionVerifyPasswordResetCode } from "../session";
import { useAppDispatch } from "../store";
import { handleError } from "../utils/handleError";
import regex from "../utils/regularExpression";
import validation from "../utils/validation";
import { PasswordRequirements } from "./PasswordRequirements";

interface ResetPasswordFormType {
  password: string;
  confirmPassword: string;
}
interface StateType {
  email: string;
  password: string;
}

export function ResetPasswordPageContents() {
  const [state, setState] = useState<StateType>();
  const dispatch = useAppDispatch();
  const addAlert = useAddAlert();

  const location = useLocation();
  const paramsString = location.search;
  const searchParams = new URLSearchParams(paramsString);

  const validationSchema = useRef(
    object().shape({
      password: string()
        .required(validation.password.required)
        .matches(regex.password, {
          message: validation.password.invalidPwdRegex,
        }),
      confirmPassword: string()
        .required(validation.confirmPassword.required)
        .oneOf([ref("password"), null], validation.confirmPassword.matched),
    }),
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetPasswordFormType>({
    resolver: yupResolver(validationSchema.current),
    mode: "onChange",
  });

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (data) => {
    try {
      const oobCode = searchParams.get("oobCode");
      if (!oobCode || !oobCode[0]) {
        message.error("oobCode is missing");
        return;
      }
      const email = await dispatch(
        sessionVerifyPasswordResetCode({
          code: oobCode,
          password: data.password,
        }),
      ).unwrap();
      if (!email) return;

      message.success("Your password has changed successfully.");
      setState({
        email,
        password: data.password,
      });
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  const onLogin = async () => {
    if (!state) return;
    await dispatch(sessionSignIn(state)).unwrap();
    dispatch(
      analyticsEnqueueEvent({
        eventName: "passwordSuccessfullyReset",
      }),
    );
  };

  return (
    <LayoutDashboard page="reset-password">
      <div className="pb-12">
        <h3
          className="font-800 text-snap-black"
          css={css`
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 6px;
          `}
        >
          Reset Password
        </h3>
        <hr
          className="border-t-black"
          css={css`
            margin-bottom: 40px;
          `}
        />

        <PasswordRequirements className="mb-8" />

        {state ? (
          <div>
            <img
              src={PadlockIcon}
              alt=""
              className="justify-self-center"
              css={css`
                margin-bottom: 34px;
                margin-left: auto;
                margin-right: auto;
              `}
            />
            <h3
              className="tracking-0.2 font-700 text-snap-ebony-clay"
              css={css`
                font-size: 19px;
                line-height: 32px;
                margin-bottom: 9px;
              `}
            >
              Congratulations, you have a new password.
            </h3>
            <p
              className="text-snap-ebony-clay"
              css={css`
                line-height: 32px;
                font-size: 19px;
              `}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit
              facilisi diam pretium, suspendisse vitae maecenas at. Convallis
              amet suspendisse hendrerit eu massa semper leo, venenatis. */}
            </p>
            <div className="grid grid-cols-2 items-end">
              <AppButton
                className="justify-self-start w-271 bg-white !text-snap-blue font-mulish"
                css={css`
                  font-size: 17px;
                `}
                onClick={() => setState(undefined)}
              >
                BACK
              </AppButton>
              <AppButton
                className="justify-self-end w-271 font-mulish"
                css={css`
                  font-size: 17px;
                `}
                onClick={() => onLogin()}
              >
                CONTINUE
              </AppButton>
            </div>
          </div>
        ) : (
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div
                    css={css`
                      height: 97px;
                    `}
                  >
                    <AppInput
                      name="password"
                      label="Type your new password"
                      placeholder="**********"
                      className="w-full max-w-330"
                      onChange={onChange}
                      value={value}
                      type="password"
                      css={css`
                        font-family: unset;
                      `}
                    />
                    <ErrorMessage
                      className="text-red"
                      css={css`
                        margin-bottom: 12px;

                        @media (max-width: 550px) {
                          margin-bottom: 8px;
                        }
                      `}
                    >
                      {errors?.password?.message}
                    </ErrorMessage>
                  </div>
                )}
              />
              <Divider dashed className="m-0 mb-2" />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="h-97">
                    <AppInput
                      name="confirmPassword"
                      label="Verify your new password"
                      placeholder="**********"
                      className="w-full max-w-330"
                      onChange={onChange}
                      value={value}
                      type="password"
                      css={css`
                        font-family: unset;
                      `}
                    />
                    <ErrorMessage className="text-red mb-10 xs:mb-8">
                      {errors?.confirmPassword?.message}
                    </ErrorMessage>
                  </div>
                )}
              />
            </div>
            <div className="flex gap-8">
              <AppButton
                className="w-full max-w-271 bg-white !text-snap-blue text-17 font-mulish"
                onClick={() => navigate("/login")}
              >
                BACK
              </AppButton>
              <AppButton
                className="w-full max-w-271 text-17 font-mulish"
                htmlType="submit"
              >
                CONTINUE
              </AppButton>
            </div>
          </form>
        )}
      </div>
    </LayoutDashboard>
  );
}
